/*GENERAL STYLING*/
html {
    background-color: #00223F;
    border-top: 0.6em solid #068E8C;
}

#root {
    margin: auto;
}

.logo-svg {
    color: #00223F;
    background-color: #FFFFFF;
    border-radius: 100%;
}

a {
    all: unset;
}

h1, h2, h3, h4 {
    font-family: raleway, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #FFFFFF;
}

h1 {
    margin-bottom: 0;
    text-align: center;
}

h2 {
    font-size: 1.2em;
}

h4 {
    font-size: 1.6em;
}

p {
    font-family: Arial, Helvetica, sans-serif;
}

hr {
    border: 1px solid #DAA788;
    color: #DAA788;
    width: 50%;
}

button {
    border: none;
    font-family: arial;
    font-size: 1em;
    color: white;
    background-color: #068E8C;
    padding: .25em .5em .25em .5em;
    border-radius: 50em;
    cursor: pointer;
}

#loading-div {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
}

#spinner {
    width: 6vw;
    position: fixed;
    top: 40vh;
    left: 0;
    margin-left: 47vw;
}

/*SIGN IN PAGE*/
#sign-in-page {
    width: 70%;
    margin: auto;
    text-align: center;
}

#sign-in-page p {
    color: #FFFFFF;
    text-align: left;
}

#new-list-button {
    font-size: 1.5em;
}

#login-form {
    background-color: white;
    padding: 0 2em 1em 2em;
    border-radius: 2em;
    display: flex;;
    flex-direction: column;
    margin-bottom: 1.5em;
    border: 2px solid #DAA788;
}

#inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#inputs input {
    width: 97%;
}

#inputs label {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    color: #00223F;
}

#buttons {
    margin-top: 0.5em;
    display: flex;
    justify-content: space-between;
}

#buttons button {
    width: fit-content;
}

#login-form h2 {
    color: #00223F;
}

#site-title {
    display: flex;
    flex-direction: column;
}

#site-title span {
    font-size: 60%;
    margin-right: 4.5em;
}

/*ACCOUNT PAGE*/
#forms {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

#authenticate-modal {
    background-color: white;
    padding: 2em;
    width: 60%;
    border-radius: 2em;
    position: fixed;
    top: 6em;
    left: 15%;
}

#close-icon {
    position: absolute;
    left: 90%;
    top: 20px;
    cursor: pointer;
}

#authenticate-modal p {
    margin: 0;
}

#delete-account {
    margin-top: 2em;
    background-color: #BD1D1D;
}

/*HEADER*/
header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: auto;
}

#header-logo {
    width: 4em;
    height: auto;
}

.sidebar, .sidebar-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.sidebar-element {
    margin: 0.5em;
    cursor: pointer;
    text-align: right;
}

.svg-container {
    width: 2em;
    height: 2em;
    border-radius: 100%;
    margin-left: .75em;
    color: #FFFFFF;
    background-color: #068E8C;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*ALL LIST PAGE*/
.list-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.all-list-spinner {
    height: 1em;
}

#new-list-form {
    margin: 1em auto 1em auto;
    width: fit-content;
    display: flex;
    align-items: center;
}

.all-lists-item {
    display: flex;
    justify-content: space-between;
    width: 80%;

    border: 2px solid #068E8C;
    margin-bottom: 0.25em;
    background-color: #FFFFFF;

    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-style: normal;

    padding: 0.5em;
    -moz-border-radius: 1.5em; 
    -webkit-border-radius: 1.5em; 
    -khtml-border-radius: 1.5em; 
    -o-border-radius: 1.5em;
    border-radius: 1.5em;

    cursor: pointer;
}

.open-list {
    border: none;
    color: #068E8C;
    font-size: 1em;
    background-color: inherit;
}

#new-item-form input {
    font-family: arial;
    font-size: 1em;
}

/*SINGLE LIST PAGE*/
.list-name {
    display: block;
    text-align: center;
    font-size: 2em;
    font-family: raleway,sans-serif;
    font-weight: 300;
    font-style: normal;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5em;
}

#collaborators-modal {
    background-color: white;
    padding: 2em;
    width: 60%;
    border-radius: 2em;
    position: fixed;
    top: 6em;
    left: 15%;
    text-align: center;
}

#collaborators-modal h2, #collaborators-modal h3 {
    color: #00223F;
}

#collaborators-modal h2 {
    text-decoration: underline;
    font-size: 1.5em;
}

#collaborators-modal #spinner {
    all: unset;
    width: 10%;
    margin: auto;
}

.collaborator {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#status-message {
    font-family: arial;
    text-align: center;
    font-size: 1em;
    position: fixed;
    padding: 0.5em;
    border-radius: 100em;
    color: #FFFFFF;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
}

#list-options {
    margin: auto;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.options {
    margin: auto;
    width: fit-content;
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
}

#list-options span {
    color: #DAA788;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

#unsaved-placeholder {
    height: 1em;
}

#unsaved-changes {
    position: relative;
    color: white !important;
}

#new-item-form {
    margin: auto;
    width: fit-content;
    display: flex;
    align-items: center;
    padding-top: 1em;
}

#list {
    padding-top: 1.3em;
    padding-bottom: 3em;
    width: 80%;
    margin: auto;
}

.list-name {
    width: 60%;
}

.list-item {
    font-size: 1.5em;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-style: normal;
}

li {
    color: #DAA788;
}

.row {
    border: 1px solid #707070;
    margin-bottom: 0.25em;
    background-color: #FFFFFF;
    color: black !important;

    padding: 0.25em;
    -moz-border-radius: 1.5em; 
    -webkit-border-radius: 1.5em; 
    -khtml-border-radius: 1.5em; 
    -o-border-radius: 1.5em;
    border-radius: 1.5em;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.move-elements {
    display: flex;
    align-items: center;
}

.handle {
    cursor: grab;
    color: #068E8C;
    font-size: 1.3em;
    padding: 0.5em;
    margin-right: 0.25em;
}

.item-name-wrapper {
    margin: auto;
    width: 90%;
}

.item-name {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.8em;
    text-align: center;
}

textarea {
    overflow: hidden;
    resize: none;
}

.item-name-wrapper textarea {
    width: 90%;
}

.trash-button {
    border: none;
    color: #BD1D1D;
    font-size: 1em;
    background-color: transparent;
}

#list li:not(.list-item) {
    visibility: hidden;
}

@media only screen 
    and (min-width: 700px)
    and (max-width: 950px) {

    #root {
        width: 80%;
    }

    #sign-in-page {
        width: 60%;
    }
}

@media only screen
    and (min-width: 951px)
    and (max-width: 1450px) {
    
    #root {
        width: 70%;
    }

    #sign-in-page {
        width: 55%;
    }
}

@media only screen
    and (min-width: 1451px) {

    #root {
        width: 60%;
    }

    #sign-in-page {
        width: 50%;
    }
}
